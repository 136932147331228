"use client"

import type { RudderstackMod } from "@jobteaser/js-tracking"

import { createContext, useContext } from "react"

const TrackingLibContext = createContext<RudderstackMod | null>(null)

const useTracking = (): RudderstackMod | null => useContext(TrackingLibContext)

export { TrackingLibContext, useTracking }
