"use client"

import type { TrackingPageViewProps } from "@/modules/tracking/types"
import type { FunctionComponent, PropsWithChildren } from "react"

import { usePathname } from "next/navigation"
import { useEffect, useMemo, useState } from "react"

import { useHref } from "@/modules/routing/hooks/useHref"
import { useTracking } from "@/modules/tracking/useTracking"
import { TrackingPageViewContext } from "@/modules/tracking/useTrackingPageView"

export const AppTrackingPageViewProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const href = useHref()
  const pathname = usePathname()
  const tracking = useTracking()
  const [trackedPathname, setTrackedPathname] = useState<string | null>(null)
  const [allPageViewProps, setPageViewProps] = useState<(TrackingPageViewProps & { __pathname: string }) | null>(null)
  const contextValue = useMemo(() => ({ setPageViewProps }), [])

  useEffect(() => {
    if (
      tracking &&
      pathname &&
      pathname !== trackedPathname &&
      allPageViewProps &&
      // eslint-disable-next-line no-underscore-dangle
      allPageViewProps.__pathname === pathname
    ) {
      const { __pathname, ...pageViewProps } = allPageViewProps
      setTrackedPathname(pathname)
      tracking.page("page_view_next", {
        ...pageViewProps,
        path: pathname,
        url: `${window.location.origin}${href}`,
      })
    }
  }, [href, tracking, pathname, allPageViewProps, trackedPathname])

  return <TrackingPageViewContext.Provider value={contextValue}>{children}</TrackingPageViewContext.Provider>
}
