import { usePathname, useSearchParams } from "next/navigation"
import { useContext } from "react"

import { ServerContext } from "@/modules/server/context"

/**
 * Retrieve the path and search from the current url with or without (default) the base url
 * @param absolute
 */
export function useHref(absolute?: boolean): string {
  const { baseUrl } = useContext(ServerContext) || { baseUrl: "" }
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const search = searchParams.toString()

  return `${absolute ? baseUrl : ""}${pathname}${search ? `?${search}` : search}`
}
