import type { TrackingPageViewProps } from "./types"

import { createContext, useContext } from "react"

type TrackingPageViewContextType = {
  setPageViewProps: (pageViewProps: (TrackingPageViewProps & { __pathname: string }) | null) => void
}

export const TrackingPageViewContext = createContext<TrackingPageViewContextType>({
  setPageViewProps: () => {},
})

export const useTrackingPageView = (): TrackingPageViewContextType => useContext(TrackingPageViewContext)
